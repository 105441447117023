<template>
  <div class="echarts">
    <div ref="main" style="width: 100%;height: 500px;"></div>
  </div>
</template>
<script>
import * as echarts from 'echarts/core'
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components'
import { LineChart } from 'echarts/charts'
import { UniversalTransition } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition
])
export default {
    name: 'EchartsTwo',
    props: {
        options: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    watch: {
        options: {
            handler() {
                this.myEcharts()
            },
            deep: true
        },
    },
    data() {
        return {
            property: 'value',
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.myEcharts()
        })
    },
    methods: {
        myEcharts() {
            var myChart = echarts.init(this.$refs.main)

            var option = {
                tooltip: {},
                legend: {
                    data: ['退款金额', '退款订单数', '退款率'], //['采购金额', '采购订单数', '采购商品数']
                    textStyle: {
                      fontSize: 14,
                      fontWeight: 'bold'
                    },
                },
                xAxis: {
                    data: this.options.xlist,
                    axisLabel: {
                      textStyle:{
                        fontSize: 14,
                        fontWeight: 'bold'
                      }
                    }
                },
                yAxis: {
                  axisLabel: {
                    textStyle:{
                      fontSize: 14,
                      fontWeight: 'bold'
                    }
                  }
                },
                series:
                    [
                        {
                            name: '退款金额',
                            type: 'line',
                            stack: 'Total',
                            data: this.options.orderPriceList
                        },
                        {
                            name: '退款订单数',
                            type: 'line',
                            stack: 'Total',
                            data: this.options.orderCountList
                        },
                        {
                            name: '退款率',
                            type: 'line',
                            stack: 'Total',
                            data: this.options.refundRateList
                        }
                    ]
                // [
                //     {
                //         name: '采购金额',
                //         type: 'line',
                //         stack: 'Total',
                //         data: this.options.orderPriceList
                //     },
                //     {
                //         name: '采购订单数',
                //         type: 'line',
                //         stack: 'Total',
                //         data: this.options.orderCountList
                //     },
                //     {
                //         name: '采购商品数',
                //         type: 'line',
                //         stack: 'Total',
                //         data: this.options.productCountList
                //     }
                // ]
            }

            // 填充数据
            myChart.setOption(option)
        }
    }
}
</script>
