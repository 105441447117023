<template>
  <div class="container bg-white p-10">
    <div class="deal_title">
      <div :class="[paramsData.type === 1 ? 'active' : '']" class="left font_colors" @click="handleDS(1)">
        交易数据
      </div>
      <div :class="[paramsData.type === 2 ? 'active' : '']" class="right font_colors" @click="handleDS(2)">
        售后数据
      </div>
    </div>
    <div class="deal_time">
      <div class="left">
        <a-range-picker ref="rangePicker" v-model="selectTime" :disabledDate="disabledDate"
          :ranges="{ '今天': [moment().hour(0).minutes(0).seconds(0), moment()], '本月': [moment().startOf('month'), moment().endOf('month')] }"
          :showTime="true" size="small" @change="rangePickerOnChange" @ok="rangePickerOnChanges" />
        <a-button class="mx-5 font_colors" size="small" type="info" @click="handleTimeBtn(1)">昨天</a-button>
        <a-button class="mr-5 font_colors" size="small" type="info" @click="handleTimeBtn(2)">近7天</a-button>
        <a-button class="mr-5 font_colors" size="small" type="info" @click="handleTimeBtn(3)">近30天</a-button>

      </div>
      <!-- <div class="right">
                <div class="btn" v-for="(it, index) in data" :key="index">{{ it.value }}</div>
            </div> -->
    </div>
    <div v-show="paramsData.type == 1" class="deal_list_card">
      <div class="list_card">
        <div class="list_card_title font_colors">采购金额</div>
        <div class="list_card_price">
          ￥{{ orderData.orderStatisticsResultl.orderPrice || 0 }}
        </div>
      </div>
      <div class="list_card">
        <div class="list_card_title font_colors">采购订单数</div>
        <div class="list_card_price">
          {{ orderData.orderStatisticsResultl.orderCount || 0 }}
        </div>
      </div>
      <div class="list_card">
        <div class="list_card_title font_colors">采购商品数</div>
        <div class="list_card_price">
          {{ orderData.orderStatisticsResultl.productCount || 0 }}
        </div>
      </div>
    </div>
    <div v-show="paramsData.type == 2" class="deal_list_card">
      <div class="list_card">
        <div class="list_card_title font_colors">退款金额</div>
        <div class="list_card_price">
          ￥{{ orderData.orderStatisticsResultl.orderPrice || 0 }}
        </div>
      </div>
      <div class="list_card">
        <div class="list_card_title font_colors">退款订单数</div>
        <div class="list_card_price">
          {{ orderData.orderStatisticsResultl.orderCount || 0 }}
        </div>
      </div>
      <div class="list_card">
        <div class="list_card_title font_colors">退款率</div>
        <div class="list_card_price">
          {{ orderData.orderStatisticsResultl.refundRate || 0 }}%
        </div>
      </div>
    </div>
    <div v-if="paramsData.type == 1 && show" class="deal_charts">
      <Echarts :options="orderData"></Echarts>
    </div>
    <div v-if="paramsData.type == 2" class="deal_charts">
      <EchartsTwo :options="orderData"></EchartsTwo>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import Echarts from '@/components/Echarts/index'
import EchartsTwo from '@/components/Echarts/indexTwo'
import * as API from '@/api/Order'
import { dateFormat } from '@/utils/utils'

export default {
  name: 'DealData',
  components: { Echarts, EchartsTwo },
  // props:{
  //   orderData:{
  //     type:Object,
  //     default(){
  //       return {}
  //     }
  //   }
  // },
  data() {
    return {
      moment,
      selectIndex: 0,
      selectTime: [],
      show: false,
      paramsData: {
        endTime: '2022-11-04',
        real: 2, //实时 || 非实时
        startTime: '2022-11-03',
        type: 1, //交易数据 || 售后数据
        userId: ''
      },
      orderData: {
        orderCountList: [0, 0],
        orderPriceList: [],
        orderStatisticsResultl: {
          orderPrice: 0,
          orderCount: 0,
          productCount: 0,
          refundRate: 0
        },
        productCountList: [],
        refundRateList: []
      }
    }
  },
  created() {
    if (this.paramsData.endTime !== '' && this.paramsData.startTime !== '') {
      this.getOrderDeal()
    }
    this.getTime()
    this.getTimes()
  },
  methods: {
    rangePickerOnChanges(dates) {
      this.paramsData.startTime = dateFormat(dates[0]._d)
      this.paramsData.endTime = dateFormat(dates[1]._d)
      if (this.paramsData.endTime !== '' && this.paramsData.startTime !== '') {
        this.getOrderDeal()
      } else {
        this.paramsData = {
          endTime: this.getTime(),
          real: 2, //实时 || 非实时
          startTime: this.getTimes(),
          type: 1, //交易数据 || 售后数据
          userId: ''
        },

          this.orderData = {
            orderCountList: [0, 0],
            orderPriceList: [],
            orderStatisticsResultl: {
              orderPrice: 0,
              orderCount: 0,
              productCount: 0,
              refundRate: 0
            },
            productCountList: [],
            refundRateList: []
          }
      }
    },
    disabledDate(date) {
      let now = new moment().startOf('day')
      return now.isBefore(date)

    },
    // 点击时间按钮
    handleTimeBtn(key) {
      if (key === 1) {
        this.selectTime = [new moment().subtract(1, 'days').startOf('day'),
        new moment().subtract(1, 'days').endOf('day')]
      }

      if (key === 2) {
        this.selectTime = [new moment().subtract(7, 'days').startOf('day'),
        new moment().subtract(1, 'days').endOf('day')]
      }

      if (key === 3) {
        this.selectTime = [new moment().subtract(30, 'days').startOf('day'),
        new moment().subtract(1, 'days').endOf('day')]
      }

      this.paramsData.startTime = this.selectTime[0].format('YYYY-MM-DD HH:mm:ss')
      this.paramsData.endTime = this.selectTime[1].format('YYYY-MM-DD HH:mm:ss')
      this.getOrderDeal()
    },
    /**
     * 日期选择器回调
     * @param dates
     * @param dateStrings
     */
    rangePickerOnChange(dates, dateStrings) {
      console.log('rangePickerOnChange日期选择器回调')
      this.paramsData.startTime = dateStrings[0]
      this.paramsData.endTime = dateStrings[1]
      console.log('日期选择器回调', dateStrings, this.selectTime)
      if (this.paramsData.endTime !== '' && this.paramsData.startTime !== '') {
        this.getOrderDeal()
      } else {
        this.paramsData = {
          endTime: this.getTime(),
          real: 2, //实时 || 非实时
          startTime: this.getTimes(),
          type: 1, //交易数据 || 售后数据
          userId: ''
        },

          this.orderData = {
            orderCountList: [0, 0],
            orderPriceList: [],
            orderStatisticsResultl: {
              orderPrice: 0,
              orderCount: 0,
              productCount: 0,
              refundRate: 0
            },
            productCountList: [],
            refundRateList: []
          }
      }
    },
    getTime() {
      let date = new Date()
      let day = date.getFullYear() + '-' + date.getMonth() + '-' + this.getShi(date.getDate() - 1) + ' ' + this.getShi(date.getHours()) +
        ':' + this.getShi(date.getMinutes()) + ':' + this.getShi(date.getSeconds())
      this.paramsData.startTime = day
      this.selectTime.concat(day)
      return day
    },
    getTimes() {
      let date = new Date()
      let day = date.getFullYear() + '-' + date.getMonth() + '-' + this.getShi(date.getDate() - 1) + ' ' + date.getHours() +
        ':' + date.getMinutes() + ':' + date.getSeconds()
      this.paramsData.endTime = day
      this.selectTime.concat(day)
      return day
    },
    getShi(val) {
      return val < 10 ? '0' + val : val
    },
    //获取统计数据
    getOrderDeal() {
      let _this = this
      API.orderDeal(this.paramsData).then(res => {
        _this.orderData = res.data
        this.show = true
      }).catch(e => e)
    },
    handleDS(i) {
      if (this.paramsData.startTime == '' && this.paramsData.endTime == '') {
        this.$message.error('时间不能为空')
        return
      }
      if (i == 1) {
        this.paramsData.type = i
        this.getOrderDeal()
      } else if (i == 2) {
        this.paramsData.type = i
        this.getOrderDeal()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.font_colors {
  font-size: 14px !important;
  font-weight: bold !important;
  color: #040404 !important;
}

.container {
  margin: 10px;
  padding: 20px 10px 10px 10px;
  // border: 1px solid red;

  .title {
    font-size: 14px;
    font-weight: bold;
    color: #040404;
  }

  .deal_title {
    height: 40px;
    border-bottom: 1px solid #c2c2c2;
    display: grid;
    grid-template-columns: repeat(2, 140px);
    grid-template-rows: 30px;
    grid-column-gap: 0;
    grid-row-gap: 0px;
    margin-top: 10px;

    .left,
    .right {
      cursor: pointer;
      // border: 1px solid red;
      line-height: 30px;
      font-size: .6rem;
      font-weight: 600;
    }

    .active {
      color: $color-main;
    }
  }

  .deal_time {
    display: flex;
    margin: 12px 0 0 0;

    .right {
      margin-left: 20px;
      display: flex;

      >div {
        cursor: pointer;
        width: 55px;
        height: 30px;
        line-height: 30px;
        margin-right: 10px;
        text-align: center;
        background-color: #fff;
        color: #b3b3b3;
        box-shadow: 0 0 10px #bebebe;
      }

      .active {
        background-color: $color-main;
        color: #fff;
      }
    }
  }

  .deal_list_card {
    display: grid;
    grid-template-columns: repeat(3, 300px);
    grid-template-rows: 160px;
    grid-column-gap: 20px;
    grid-row-gap: 0px;
    margin: 10px 0 0 0;

    .list_card {
      position: relative;
      box-shadow: 0 0 10px #bebebe;
      border-radius: 5px;
      margin-top: 10px;

      .list_card_title {
        position: absolute;
        top: 10px;
        left: 10px;
        font-size: .7rem;
        color: #c2c2c2;
      }

      .list_card_price {
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        font-size: 2rem;
        font-weight: 500;
        color: #00c48a;
      }
    }
  }

  .deal_charts {
    box-shadow: 0 0 10px #bebebe;
    margin: 15px 0 0 0;
  }
}
</style>
